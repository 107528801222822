import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/hana_profile.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 407px;
  max-height: 407px;
  border-radius: 18px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Title variant="card" fontSize="24px">
                About me
              </Title>
              <Text variant="small" my={3}>
                Hi. My name is Hana and I am the flower grower and software
                developer behind Petaler.
              </Text>
              <Text variant="small" my={3}>
                It had always been on my bucket list to have a roadside flower
                stand. My name literally means 'flower' in Japanese (though it
                could also mean 'nose' so maybe we shouldn't take that to mean
                too much). Growing up in the Chicago suburbs, I figured the
                flower stand would happen when I was old and had retired
                somewhere country-like. However, in 2016 I moved to Vermont and
                had access to a little bit of land, and the flower bug got me. I
                think it's a common experience, it starts as "just a little
                garden", and then you are 3 seed catalogs deep (the kind without
                the pictures), and the garden grows in size exponentially each
                year, and tadaa it became a farm, and you are dealing with
                schedules, and orders, and invoices, and emails.
              </Text>
              <Text my={3}>
                I've heard it said that what you are meant to do is at the
                intersection of what you are good at, what you love, and how you
                want to serve the world.
              </Text>
              <Text variant="small">
                For the last 4 years I have been a part time flower grower, full
                time software developer. I love both. What I am good at? Writing
                code, developing software. What I love? Flowers and people brave
                enough to start and run their own businesses. How do I want to
                serve the world? Enable people who start and run their own
                businesses to succeed and leave the planet a little happier. Out
                came Petaler.
              </Text>
              <Text variant="small" mt={4}>
                Shoot me an email I'd love to chat.
              </Text>
              <Text variant="small" mt={4}>
                hana@getpetaler.com
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
