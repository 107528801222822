import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Title, Text, Button, List } from "../../components/Core";
import { device } from "../../utils";
import imgCase1 from "../../assets/image/jpeg/hanafield.jpg";
import imgCase2 from "../../assets/image/jpeg/hoophouse.jpg";
import imgCase3 from "../../assets/image/jpeg/IMG_9503web.jpg";

const ImgContainer = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
`;

const HalfImage = styled(Box)`
  min-width: 240px;
  max-width: 540px;
  min-height: 240px;
  max-height: 540px;
  border-radius: 18px;
  overflow: hidden;
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;
const ContentCard = ({ title, subtitle, children }) => (
  <Box pb={["40px", null, "65px"]}>
    <Title variant="card">{title}</Title>
    <Text variant="small" mb="18px">
      {subtitle}
    </Text>
    <Text variant="small">{children}</Text>
  </Box>
);

const CaseDetails = () => (
  <>
    <Section className="position-relative" borderBottom="1px solid #eae9f2;">
      <Container>
        <ImgContainer className="text-center" pb={["50px", null, "75px"]}>
          <img src={imgCase1} alt="" className="img-fluid" />
        </ImgContainer>
        <Row className="mb-4">
          <Col lg="12" xl="10" className="offset-xl-1">
            <Title>Who we strive to be</Title>
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="5" className="offset-xl-1">
            <ContentCard
              title="Confident"
              subtitle="Capable, Humble, Trustworthy"
            >
              We are proud of the work we are doing but are never too smart to
              learn and improve. We know our tools are truly helpful and that we
              can provide a quality service.
            </ContentCard>
            <ContentCard title="Authentic" subtitle="Genuine, Honest, Warm">
              We are transparent in our actions and motivations. We mean what we
              say and always communicate in an approachable and personable way.
            </ContentCard>
          </Col>
          <Col lg="6" xl="5" className="offset-xl-1">
            <ContentCard
              title="Positive"
              subtitle="Optimistic, Enthusiastic, Encouraging"
            >
              We are full of hope for tomorrow and excited to put in the work to
              help our community grow. We are eager to support members of our
              community and celebrate their success.
            </ContentCard>
            <ContentCard
              title="Professional"
              subtitle="Efficient, Organized, Reliable"
            >
              We are clear and coherent in our communications. We are thoughtful
              and steady in everything that we do and are considerate when
              engaging with members of our community.
            </ContentCard>
          </Col>
        </Row>
        <ImgContainer className="text-center" pb={["50px", null, "75px"]}>
          <img src={imgCase2} alt="" className="img-fluid" />
        </ImgContainer>
        <Row className="mb-4 mt-30">
          <Col lg="12" xl="10" className="offset-xl-1">
            <Title>What's important to us</Title>
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="5" className="offset-xl-1">
            <ContentCard title="Accessibility">
              We make buying and selling local flowers as easy and efficient as
              possible. We provide users with a quick, seamless,
              frustration-free experience and are with them every step of the
              way.
            </ContentCard>
            <ContentCard title="Community">
              There is room for everyone at the table. As a community, we
              believe we can shift the floral industry to sourcing local. We
              choose to encourage and support each other in pursuit of this
              goal, knowing that as we moved towards it, we all succeed.
            </ContentCard>
          </Col>
          <Col lg="6" xl="5" className="offset-xl-1">
            <ContentCard title="Transparency">
              Growing flowers can be a messy process, but we don’t think that
              selling them should be. We are clear and straightforward in our
              process, our offerings, and why we do what we do.
            </ContentCard>
            <ContentCard title="Growth">
              Our farmers work incredibly hard every day to bring beauty into
              their communities, and it’s our greatest joy to see them succeed.
              We use our platform to encourage and enable our farmers to thrive.
            </ContentCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseDetails;
