import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";

import CaseDetails from "../sections/who/CaseDetails";
import Fact from "../sections/who/Author";

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title>We are here for you.</Title>
                <Text variant="small" mb={12}>
                  It's nice to meet you, fellow flower lover.
                </Text>
                <Text>
                  Ultimately, Petaler is for you. We want to hear from you,
                  learn from you, celebrate with you, and enable you. We believe
                  that together we have the power to shift the floral industry
                  to sourcing locally.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseDetails />
        <Fact />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
